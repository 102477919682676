import { Box, Flex } from '@chakra-ui/react'
import { Grid, Link, Text } from '@stars-ecom/shared-atoms-ui'
import { Phone, WebsiteContext } from '@stars-ecom/shared-organisms-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'

import { siteFormatter } from '../utils/metaTitleFormatter'

const TVChannels = (props) => {
  const { pageContext } = props
  const { channels } = pageContext
  const websiteContext = useContext(WebsiteContext)
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      pb="15px"
      backgroundColor="#f6f3f1"
      w="100%">
      <Helmet
        bodyAttributes={{
          class: 'chakra-ui-light'
        }}>
        <meta charSet="utf-8" />
        <title>{`Nos diffusions - ${siteFormatter(process.env.GATSBY_API_WEBSITE)}`}</title>
        <meta
          name="description"
          content={`Retrouvez en ligne sur ${siteFormatter(
            process.env.GATSBY_API_WEBSITE
          )}, l'ensemble des produits présentés lors de votre émission de télé achat. Commandez en ligne !`}
        />
      </Helmet>
      <Flex
        maxWidth="1000px"
        direction="column"
        margin="auto"
        mb="45px"
        pt={{ base: '20px', md: '45px' }}
        bg="#f6f3f1">
        <Text
          color={websiteContext?.mainColor}
          w="100%"
          fontSize="30px"
          fontFamily="PT Sans Narrow"
          fontWeight="700">
          {channels?.title?.toUpperCase()}
        </Text>
        <Grid
          className="channels-grid"
          m="25px auto 0"
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
          fontFamily="PT Sans"
          gap="12px">
          {channels?.tvChannels?.map((channel, i) => (
            <Flex
              key={`channel_${i}`}
              bg="white"
              h="364px"
              w="320px"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center">
              <Flex h="215px" width="100%" alignItems="center" justifyContent="center">
                <GatsbyImage
                  image={channel?.logo?.image?.childImageSharp?.gatsbyImageData}
                  alt={channel?.title}
                  style={{ maxWidth: '70%', maxHeight: '70%' }}
                />
              </Flex>
              <Box
                dangerouslySetInnerHTML={{ __html: channel?.description }}
                fontSize="14px"
                textAlign="center"
                mb="30px"
              />
              <Flex display="inline-block" max-width="80%" verticalAlign="middle">
                <Link to={'tel:' + channel?.phoneNumber?.phoneNumber.replace(/ /g, '')}>
                  <Phone
                    phoneNumber={channel?.phoneNumber?.phoneNumber}
                    price={channel?.phoneNumber?.phoneNumberPrice}
                    style={{ marginBottom: '20px' }}
                    height="32px"
                    fontSize="16px"
                    color="#A50F78"
                  />
                </Link>
              </Flex>
            </Flex>
          ))}
        </Grid>
      </Flex>
    </Flex>
  )
}

export default TVChannels
